import styled from '@emotion/styled';
import ButtonStyle from '../../components/global/Button.style';
import ContainerStyle from '../../components/global/Container.style';
import IconStyle from '../../components/global/Icon.style';
import StickyableContainer from '../../components/StickyableContainer';

export default styled(StickyableContainer)`
  &.blue-box {
    .layout-box {
      gap: 0;
    }
    .layout-box > .heading-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.pacificBlue};
      border-radius: 3px 3px 0 0;
      padding: 20px 10px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        bottom: 0;
        left: calc(50% - 6px);
        background-color: inherit;
        transform: translateY(6px) rotate(45deg);
        z-index: 1;
      }
    }
    ${ButtonStyle}.main-button {
      max-width: 365px;
      align-self: flex-start;
      width: 100%;
    }

    .layout-box > ${ContainerStyle} {
      ${({ theme }) => theme.media.laptop} {
        --pt: 30px;
        --pr: 30px;
        --pb: 30px;
        --pl: 30px;
      }
    }
  }
  & > .layout-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .separator-bottom {
    position: absolute;
    top: 100%;
    margin: calc(var(--mb) / 2) 0 0;
  }
  .separator-top {
    position: absolute;
    bottom: 100%;
    margin: 0 0 calc(var(--mt) / 2);
  }
  .asset-links {
    list-style: none;
    padding-left: 0;
    ${ButtonStyle} {
      ${IconStyle} {
        margin-right: 13px;
      }
    }
  }
`;
