import styled from '@emotion/styled';
import Container from './global/Container';

export default styled(Container)`
  --height: auto;
  --top: 0;
  html:not(.hs-inline-edit) & {
    ${({ theme }) => theme.media.desktopSmall} {
      margin-top: 0px;
      position: absolute;
      width: 100%;
      height: calc(var(--height) - var(--mt) - var(--mb));
      top: var(--top);
      z-index: 2;
      background: none;
      pointer-events: none;
      .layout-box {
        pointer-events: initial;
        position: sticky;
        top: calc(
          var(--top-bar-height) + var(--header-menu-height) +
            var(--top-bar-offset) + var(--header-menu-offset) + 15px
        );
        transition: top 350ms ease-in-out;
      }
    }
  }
`;
