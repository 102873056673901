import React from 'react';
import Container from './global/Container';
import StickyContainer from './StickyContainer';

// eslint-disable-next-line react/display-name
const StickyableContainer = React.forwardRef(({ sticky, ...p }, ref) => {
  if (sticky) {
    return <StickyContainer {...p} ref={ref} />;
  } else {
    return <Container {...p} ref={ref} />;
  }
});
export default StickyableContainer;
