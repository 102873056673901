import React, { useEffect, useRef } from 'react';
import StyledStickyContainer from './StickyContainer.style';

// eslint-disable-next-line react/display-name
const StickyContainer = React.forwardRef((p, container) => {
  if (!container) container = useRef();

  useEffect(() => {
    const layouts = { from: null, to: null };
    const values = { height: null, top: null };

    const findLayouts = () => {
      // Find layout break components immediately before and after the sticky container
      if (!container.current) {
        layouts.from = null;
        layouts.to = null;
        return;
      }
      const elems = Array.from(
        document.querySelectorAll("div[data-name='layout-break']")
      );
      elems.push(container.current);
      elems.sort((a, b) =>
        a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1
      );
      const ix = elems.indexOf(container.current);
      layouts.from = elems[ix - 1];
      layouts.to = elems[ix + 1];
    };
    const findSize = () => {
      // Determine the size and position of the container based on the layout break components
      const fromLayout = layouts.from;
      const toLayout = layouts.to;
      if (!fromLayout || !toLayout || !container.current) return;
      const minHeight = container.current.children[0]?.clientHeight + 60;
      let height = toLayout.offsetTop - fromLayout.offsetTop;
      if (height < minHeight) {
        toLayout.style.setProperty(
          'margin-bottom',
          `${minHeight - height + 120}px`
        );
        height = minHeight;
      }
      const top = fromLayout.offsetTop;
      if (values.height !== height) {
        values.height = height;
        container.current.style.setProperty('--height', `${height}px`);
      }
      if (values.top !== top) {
        values.top = top;
        container.current.style.setProperty('--top', `${top}px`);
      }
    };
    findLayouts();
    findSize();
    const intervalLayouts = setInterval(findLayouts, 1000);
    const intervalSize = setInterval(findSize, 100);
    return () => {
      clearInterval(intervalLayouts);
      clearInterval(intervalSize);
    };
  }, []);

  return (
    <StyledStickyContainer ref={container} {...p} layoutColumn="secondary" />
  );
});
export default StickyContainer;
