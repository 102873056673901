import styled from '@emotion/styled';

export default styled.hr`
  display: block;
  height: 0px;
  width: 100%;
  border: none;
  border-bottom: solid thin ${({ theme }) => theme.colors.quartz};
  margin: 20px 0;
`;
